/* You can add global styles to this file, and also import other style files */
@import './app/styles/index.scss';
@import '@ctrl/ngx-emoji-mart/picker';



body {
  font-family: 'Lato';
  height: 100%;
  margin: 0;
  color: #fff;
}

.cr-pointer {
  cursor: pointer;
}

.margin-section {
  @media (min-width:767px) {
    margin-top: 24px;
  }

}

.padding-mobile {
  @media (max-width:575px) {
    padding-left: 29px;
    padding-right: 29px;
  }

}

.cookie-modal {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(0, 0, 0, 0.85) 100%);
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-sizing: border-box;
  border: 1.5px solid rgba(255, 255, 255, 0.050);
}

.separate-back {
  padding-top: 40px;
  padding-bottom: 24px;
  color: #132046;
  font-family: Lato;
  font-size: 18px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.container {
  @media(min-width:1199px) {
    max-width: 1200px !important;
  }
}

.active-tower {
  .card-service {
    background-color: white !important;
    color: $blue-no5;
    min-height: 630px;
    padding: 41px 28px !important;
    margin: auto;

    .img-card {
      display: inline-block;
    }

    .btn-view-more {
      display: block;
    }

    .title {
      font-size: 26px;
      line-height: 32px;
      font-weight: bold;
    }
  }
}

.cssToolTip {
  position: relative;
}

.cssToolTip:hover .content-tool {
  display: inline;
}

.cssToolTip .content-tool {
  display: none;
}





//////// Tooltip icon DevOps
.cssToolTip-dev {
  position: relative;
}

.cssToolTip-dev:hover .content-tool {
  display: inline;
}

.cssToolTip-dev .content-tool {
  display: none;
}


/////////Botón Blanco/////////////////
.btn-bubble {
  background-color: transparent;
  border: 1px solid #0075E2;
  border-radius: 0.4em;
  padding: 0.5em 2em;
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
  width: 184px;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 144px;
  }
}

.btn-bubble::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0075E2;
  position: absolute;
  border-radius: 50px;
  transition: all 1s ease;
  transform: translateX(-101%);
  z-index: -1;
}

.btn-bubble:hover:before {
  transform: translateX(0);
  border-radius: 0;
}

.btn-bubble:hover {
  color: #ffffff;
  z-index: 5;
}


//////////////////Botón Azul//////////////////
.btn-bubbleWhite {
  background-color: #0075E2;
  border: none !important;
  border-radius: 0.4em;
  padding: 0.5em 2em;
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
  font-size: 18px;
  overflow: hidden;
  width: 184px;

  @media (max-width: 767px) {
    width: 144px;
    font-size: 16px;
  }
}

.btn-bubbleWhite::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #004D96;
  position: absolute;
  border-radius: 50px;
  transition: all 1s ease;
  transform: translateX(-101%);
  z-index: -1;

}

.btn-bubbleWhite:hover:before {
  transform: translateX(0);
  border-radius: 0;
}

.btn-bubbleWhite:hover {
  color: #FFFFFF !important;
  z-index: 5;
}


////////////////Botón Gris//////////////////
.btn-bubbleGray {
  background-color: rgba(227, 227, 227, .35);
  border: 1px solid #FFFFFF;
  border-radius: 0.4em;
  padding: 0.5em 2em;
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
  font-size: 18px;
  overflow: hidden;
  width: 184px;

  @media (max-width: 767px) {
    width: 144px;
    font-size: 16px;
  }
}

.btn-bubbleGray::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0075E2;
  position: absolute;
  border-radius: 50px;
  transition: all 1s ease;
  transform: translateX(-101%);
  z-index: -1;

}

.btn-bubbleGray:hover:before {
  transform: translateX(0);
  border-radius: 0;
}

.btn-bubbleGray:hover {
  color: #FFFFFF !important;
  z-index: 5;
}


/* ESTILOS CARGA DE ICONOS SITE 2BCORE */

@font-face {
  font-family: 'icomoon';
  src: url('assets/fonts/icomoon.eot?54tf7d');
  src: url('assets/fonts/icomoon.eot?54tf7d#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.woff2?54tf7d') format('woff2'),
    url('assets/fonts/icomoon.ttf?54tf7d') format('truetype'),
    url('assets/fonts/icomoon.woff?54tf7d') format('woff'),
    url('assets/fonts/icomoon.svg?54tf7d#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//clases actualizadas ultima version icomoon


.icon-collapsed:before {
  content: "\e914";
  color: #fff;
}
.icon-expanded:before {
  content: "\e915";
  color: #fff;
}
.icon-cookies .path1:before {
  content: "\e900";
  color: rgb(114, 59, 17);
}
.icon-cookies .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-cookies .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(223, 165, 122);
}
.icon-cookies .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-cookies .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-cookies .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-cookies .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-cookies .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-cookies .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-cookies .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(114, 59, 17);
}
.icon-bcore-w:before {
  content: "\e939";
  color: #fff;
}
.icon-bcore .path1:before {
  content: "\e93a";
  color: rgb(0, 117, 226);
}
.icon-bcore .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 117, 226);
}
.icon-bcore .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(0, 117, 226);
}
.icon-bcore .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(0, 117, 226);
}
.icon-bcore .path5:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(154, 152, 152);
}
.icon-bcore .path6:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(154, 152, 152);
}
.icon-bcore .path7:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(0, 117, 226);
}
.icon-close-1:before {
  content: "\e941";
  color: #fff;
}
.icon-close:before {
  content: "\e942";
  color: #132046;
}
.icon-language-w:before {
  content: "\e943";
  color: #fff;
}
.icon-language:before {
  content: "\e944";
  color: #FFFFFF;
}
.icon-login-d:before {
  content: "\e945";
  color: #fff;
}
.icon-login:before {
  content: "\e946";
  color: #132046;
}
.icon-search-active-d .path1:before {
  content: "\e947";
  color: rgb(255, 255, 255);
}
.icon-search-active-d .path2:before {
  content: "\e948";
  margin-left: -0.90625em;
  color: rgb(255, 255, 255);
  opacity: 0.2;
}
.icon-search-active .path1:before {
  content: "\e949";
  color: none;
}
.icon-search-active .path2:before {
  content: "\e94a";
  margin-left: -0.90625em;
  color: rgb(255, 255, 255);
  opacity: 0.2;
}
.icon-search-active .path3:before {
  content: "\e94b";
  margin-left: -0.90625em;
  color: rgb(19, 32, 70);
  opacity: 0.2;
}
.icon-search-d:before {
  content: "\e94c";
  color: #fff;
}
.icon-search:before {
  content: "\e94d";
  color: #132046;
}
.icon-Regresar:before {
  content: "\e90a";
  color: #132046;
}
.icon-VP360:before {
  content: "\e90b";
}
.icon-Objetivo:before {
  content: "\e90c";
  color: #0075e2;
}
.icon-Mision:before {
  content: "\e90d";
  color: #0075e2;
}
.icon-Vision:before {
  content: "\e90e";
  color: #0075e2;
}
.icon-Etica:before {
  content: "\e90f";
  color: #0075e2;
}
.icon-Empathize:before {
  content: "\e9f0";
  color: #fff;
}
.icon-Define:before {
  content: "\e9f2";
  color: #fff;
}
.icon-Ideate:before {
  content: "\e9f1";
  color: #fff;
}
.icon-Prototype:before {
  content: "\e9f3";
  color: #fff;
}
.icon-DevOps .path1:before {
  content: "\e9f5";
  color: rgb(124, 202, 98);
}
.icon-DevOps .path2:before {
  content: "\e9f6";
  margin-left: -2.1455078125em;
  color: rgb(0, 117, 226);
}
.icon-DevOps .path3:before {
  content: "\e9f7";
  margin-left: -2.1455078125em;
  color: rgb(124, 202, 98);
}
.icon-DevOps .path4:before {
  content: "\e9f8";
  margin-left: -2.1455078125em;
  color: rgb(0, 117, 226);
}
.icon-Test-and-Learn:before {
  content: "\e9f4";
  color: #fff;
}
.icon-IQNet:before {
  content: "\e9ef";
  color: #bac5cf;
}
.icon-ISO-27001 .path1:before {
  content: "\e9bb";
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path2:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path3:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path4:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path5:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path6:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path7:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path8:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path9:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path10:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path11:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path12:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path13:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path14:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path15:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path16:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path17:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path18:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path19:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path20:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path21:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path22:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path23:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path24:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path25:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path26:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path27:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path28:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path29:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path30:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path31:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path32:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path33:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path34:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path35:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path36:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path37:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path38:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path39:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ISO-27001 .path40:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path41:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path42:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path43:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path44:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path45:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path46:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path47:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path48:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path49:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path50:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path51:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-ISO-27001 .path52:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(186, 197, 207);
}
.icon-Success .path1:before {
  content: "\e910";
  color: rgb(0, 0, 0);
}
.icon-Success .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.9;
}
.icon-Warning .path1:before {
  content: "\e911";
  color: rgb(0, 0, 0);
}
.icon-Warning .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.9;
}
.icon-Error .path1:before {
  content: "\e912";
  color: rgb(0, 0, 0);
}
.icon-Error .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.9;
}
.icon-Enterprise-Solutions .path1:before {
  content: "\e913";
  color: rgb(0, 63, 155);
}
.icon-Enterprise-Solutions .path2:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(153, 196, 255);
}
.icon-Enterprise-Solutions .path3:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(85, 156, 255);
}
.icon-Solutions-Development .path1:before {
  content: "\e916";
  color: rgb(96, 96, 96);
}
.icon-Solutions-Development .path2:before {
  content: "\e964";
  margin-left: -1.5361328125em;
  color: rgb(85, 156, 255);
}
.icon-Solutions-Development .path3:before {
  content: "\e966";
  margin-left: -1.5361328125em;
  color: rgb(176, 176, 177);
}
.icon-Solutions-Development .path4:before {
  content: "\e968";
  margin-left: -1.5361328125em;
  color: rgb(150, 150, 152);
}
.icon-Solutions-Development .path5:before {
  content: "\e96a";
  margin-left: -1.5361328125em;
  color: rgb(202, 202, 203);
  opacity: 0.7;
}
.icon-Solutions-Development .path6:before {
  content: "\e96c";
  margin-left: -1.5361328125em;
  color: rgb(255, 255, 255);
}
.icon-Solutions-Development .path7:before {
  content: "\e96f";
  margin-left: -1.5361328125em;
  color: rgb(255, 255, 255);
}
.icon-Solutions-Development .path8:before {
  content: "\e970";
  margin-left: -1.5361328125em;
  color: rgb(255, 255, 255);
}
.icon-Specialized-Services .path1:before {
  content: "\e917";
  color: rgb(0, 63, 155);
}
.icon-Specialized-Services .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(85, 156, 255);
}
.icon-Specialized-Services .path3:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(0, 117, 226);
}
.icon-Normalization--Processes .path1:before {
  content: "\e919";
  color: rgb(10, 117, 221);
}
.icon-Normalization--Processes .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Normalization--Processes .path3:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-BInstitute .path1:before {
  content: "\e91c";
  color: rgb(11, 118, 228);
}
.icon-BInstitute .path2:before {
  content: "\e91d";
  margin-left: -1.03125em;
  color: rgb(11, 118, 228);
}
.icon-BInstitute .path3:before {
  content: "\e91e";
  margin-left: -1.03125em;
  color: rgb(96, 96, 96);
}
.icon-BInstitute .path4:before {
  content: "\e91f";
  margin-left: -1.03125em;
  color: rgb(96, 96, 96);
}
.icon-BTalents .path1:before {
  content: "\e920";
  color: rgb(10, 117, 224);
}
.icon-BTalents .path2:before {
  content: "\e921";
  margin-left: -1.078125em;
  color: rgb(96, 96, 96);
}
.icon-BTalents .path3:before {
  content: "\e922";
  margin-left: -1.078125em;
  color: rgb(150, 150, 152);
}
.icon-BTalents .path4:before {
  content: "\e923";
  margin-left: -1.078125em;
  color: rgb(150, 150, 152);
}
.icon-BTalents .path5:before {
  content: "\e924";
  margin-left: -1.078125em;
  color: rgb(150, 150, 152);
}
.icon-BTalents .path6:before {
  content: "\e925";
  margin-left: -1.078125em;
  color: rgb(96, 96, 96);
}
.icon-BTalents .path7:before {
  content: "\e926";
  margin-left: -1.078125em;
  color: rgb(10, 117, 224);
}
.icon-BTalents .path8:before {
  content: "\e927";
  margin-left: -1.078125em;
  color: rgb(10, 117, 224);
}
.icon-Izquierda:before {
  content: "\e928";
}
.icon-Derecha:before {
  content: "\e929";
}
.icon-Mas:before {
  content: "\e92a";
  color: #559cff;
}
.icon-Menos:before {
  content: "\e92b";
  color: #132046;
}
.icon-red_hat_color .path1:before {
  content: "\e95b";
  color: rgb(89, 89, 89);
}
.icon-red_hat_color .path2:before {
  content: "\e95c";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path3:before {
  content: "\e985";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path4:before {
  content: "\ea07";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path5:before {
  content: "\ea08";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path6:before {
  content: "\ea72";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path7:before {
  content: "\ea73";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path8:before {
  content: "\ea74";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path9:before {
  content: "\ea75";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path10:before {
  content: "\ea76";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path11:before {
  content: "\ea77";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path12:before {
  content: "\ea78";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path13:before {
  content: "\ea79";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path14:before {
  content: "\ea7a";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path15:before {
  content: "\ea7b";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path16:before {
  content: "\ea7c";
  margin-left: -2.095703125em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_color .path17:before {
  content: "\ea7d";
  margin-left: -2.095703125em;
  color: rgb(238, 0, 0);
}
.icon-red_hat_color .path18:before {
  content: "\ea7e";
  margin-left: -2.095703125em;
  color: rgb(0, 0, 0);
}
.icon-red_hat_color .path19:before {
  content: "\ea7f";
  margin-left: -2.095703125em;
  color: rgb(0, 0, 0);
}
.icon-Microsoft-Azure-color .path1:before {
  content: "\ea3e";
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path2:before {
  content: "\ea3f";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path3:before {
  content: "\ea40";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path4:before {
  content: "\ea41";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path5:before {
  content: "\ea42";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path6:before {
  content: "\ea43";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-up-alliances:before {
  content: "\e975";
  color: #132046;
}

.icon-down-alliances:before {
  content: "\e953";
  color: #132046;
}
.icon-Microsoft-Azure-color .path7:before {
  content: "\ea44";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path8:before {
  content: "\ea45";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path9:before {
  content: "\ea46";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path10:before {
  content: "\ea47";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path11:before {
  content: "\ea48";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path12:before {
  content: "\ea49";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path13:before {
  content: "\ea4a";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path14:before {
  content: "\ea4b";
  margin-left: -6.880859375em;
  color: rgb(54, 187, 238);
}
.icon-Microsoft-Azure-color .path15:before {
  content: "\ea4c";
  margin-left: -6.880859375em;
  color: rgb(46, 75, 135);
}
.icon-Microsoft-Azure-color .path16:before {
  content: "\ea4d";
  margin-left: -6.880859375em;
  color: rgb(67, 119, 207);
}
.icon-Microsoft-Azure-color .path17:before {
  content: "\ea4e";
  margin-left: -6.880859375em;
  color: rgb(123, 201, 241);
}
.icon-aws-color .path1:before {
  content: "\e95e";
  color: rgb(35, 49, 61);
}
.icon-aws-color .path2:before {
  content: "\e95f";
  margin-left: -1.681640625em;
  color: rgb(244, 153, 10);
}
.icon-aws-color .path3:before {
  content: "\e960";
  margin-left: -1.681640625em;
  color: rgb(35, 49, 61);
}
.icon-aws-color .path4:before {
  content: "\e961";
  margin-left: -1.681640625em;
  color: rgb(35, 49, 61);
}
.icon-aws-color .path5:before {
  content: "\e962";
  margin-left: -1.681640625em;
  color: rgb(244, 153, 10);
}
.icon-TechData:before {
  content: "\e9a1";
  color: #0054a4;
}
.icon-Ingram_Micro:before {
  content: "\e9a6";
  color: #3f71c0;
}
.icon-IBM:before {
  content: "\e92c";
  color: #000000;
}
.icon-Microsoft-Azure:before {
  content: "\e92d";
  color: #35a8e1;
}
.icon-NYCE .path1:before {
  content: "\e92e";
  color: rgb(115, 129, 129);
}
.icon-NYCE .path2:before {
  content: "\e92f";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path3:before {
  content: "\e930";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path4:before {
  content: "\e931";
  margin-left: -2.5419921875em;
  color: rgb(0, 128, 129);
}
.icon-NYCE .path5:before {
  content: "\e932";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path6:before {
  content: "\e933";
  margin-left: -2.5419921875em;
  color: rgb(1, 127, 128);
}
.icon-NYCE .path7:before {
  content: "\e934";
  margin-left: -2.5419921875em;
  color: rgb(1, 127, 128);
}
.icon-NYCE .path8:before {
  content: "\e935";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path9:before {
  content: "\e936";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path10:before {
  content: "\e937";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path11:before {
  content: "\e938";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path12:before {
  content: "\e94e";
  margin-left: -2.5419921875em;
  color: rgb(0, 128, 129);
}
.icon-NYCE .path13:before {
  content: "\e94f";
  margin-left: -2.5419921875em;
  color: rgb(0, 128, 129);
}
.icon-NYCE .path14:before {
  content: "\e9f9";
  margin-left: -2.5419921875em;
  color: rgb(1, 127, 128);
}
.icon-NYCE .path15:before {
  content: "\e9fa";
  margin-left: -2.5419921875em;
  color: rgb(1, 127, 128);
}
.icon-NYCE .path16:before {
  content: "\e9fb";
  margin-left: -2.5419921875em;
  color: rgb(227, 0, 44);
}
.icon-NYCE .path17:before {
  content: "\e9fc";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path18:before {
  content: "\e9fd";
  margin-left: -2.5419921875em;
  color: rgb(227, 0, 44);
}
.icon-NYCE .path19:before {
  content: "\e9fe";
  margin-left: -2.5419921875em;
  color: rgb(227, 0, 44);
}
.icon-NYCE .path20:before {
  content: "\e9ff";
  margin-left: -2.5419921875em;
  color: rgb(227, 0, 44);
}
.icon-NYCE .path21:before {
  content: "\ea00";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path22:before {
  content: "\ea01";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path23:before {
  content: "\ea02";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path24:before {
  content: "\ea03";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path25:before {
  content: "\ea04";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path26:before {
  content: "\ea05";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-NYCE .path27:before {
  content: "\ea06";
  margin-left: -2.5419921875em;
  color: rgb(115, 129, 129);
}
.icon-Redis-labs .path1:before {
  content: "\ea09";
  color: rgb(163, 36, 34);
}
.icon-Redis-labs .path2:before {
  content: "\ea0a";
  margin-left: -2.9638671875em;
  color: rgb(220, 56, 44);
}
.icon-Redis-labs .path3:before {
  content: "\ea0b";
  margin-left: -2.9638671875em;
  color: rgb(163, 36, 34);
}
.icon-Redis-labs .path4:before {
  content: "\ea0c";
  margin-left: -2.9638671875em;
  color: rgb(220, 56, 44);
}
.icon-Redis-labs .path5:before {
  content: "\ea0d";
  margin-left: -2.9638671875em;
  color: rgb(163, 36, 34);
}
.icon-Redis-labs .path6:before {
  content: "\ea0e";
  margin-left: -2.9638671875em;
  color: rgb(220, 56, 44);
}
.icon-Redis-labs .path7:before {
  content: "\ea0f";
  margin-left: -2.9638671875em;
  color: rgb(255, 255, 255);
}
.icon-Redis-labs .path8:before {
  content: "\ea10";
  margin-left: -2.9638671875em;
  color: rgb(116, 17, 19);
}
.icon-Redis-labs .path9:before {
  content: "\ea11";
  margin-left: -2.9638671875em;
  color: rgb(172, 39, 36);
}
.icon-Redis-labs .path10:before {
  content: "\ea12";
  margin-left: -2.9638671875em;
  color: rgb(73, 76, 77);
}
.icon-Salesforce .path1:before {
  content: "\ea13";
  color: rgb(0, 161, 224);
}
.icon-Salesforce .path2:before {
  content: "\ea14";
  margin-left: -1.4482421875em;
  color: rgb(255, 255, 254);
}
.icon-Salesforce .path3:before {
  content: "\ea15";
  margin-left: -1.4482421875em;
  color: rgb(255, 255, 254);
}
.icon-SAP .path1:before {
  content: "\ea16";
  color: rgb(0, 0, 0);
}
.icon-SAP .path2:before {
  content: "\ea17";
  margin-left: -2.0126953125em;
  color: rgb(255, 255, 255);
}
.icon-Terraform .path1:before {
  content: "\ea18";
  color: rgb(0, 0, 0);
}
.icon-Terraform .path2:before {
  content: "\ea19";
  margin-left: -4.2080078125em;
  color: rgb(0, 0, 0);
}
.icon-Terraform .path3:before {
  content: "\ea1a";
  margin-left: -4.2080078125em;
  color: rgb(64, 64, 178);
}
.icon-Terraform .path4:before {
  content: "\ea1b";
  margin-left: -4.2080078125em;
  color: rgb(92, 78, 229);
}
.icon-Terraform .path5:before {
  content: "\ea1c";
  margin-left: -4.2080078125em;
  color: rgb(92, 78, 229);
}
.icon-Twilio:before {
  content: "\ea1d";
  color: #f22f46;
}
.icon-VTEX:before {
  content: "\ea1e";
  color: #f36;
}
.icon-list_collapsed:before {
  content: "\ea1f";
  color: #0075e2;
}
.icon-list_expanded:before {
  content: "\ea20";
}
.icon-bullet .path1:before {
  content: "\ea21";
  color: rgb(0, 117, 226);
}
.icon-bullet .path2:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-red_hat_white:before {
  content: "\ea80";
  color: #fff;
}
.icon-red_hat_gray .path1:before {
  content: "\ea81";
  color: rgb(216, 222, 228);
}
.icon-red_hat_gray .path2:before {
  content: "\ea82";
  margin-left: -2.095703125em;
  color: rgb(216, 222, 228);
}
.icon-red_hat_gray .path3:before {
  content: "\ea83";
  margin-left: -2.095703125em;
  color: rgb(216, 222, 228);
}
.icon-red_hat_gray .path4:before {
  content: "\ea84";
  margin-left: -2.095703125em;
  color: rgb(216, 222, 228);
}
.icon-red_hat_gray .path5:before {
  content: "\ea85";
  margin-left: -2.095703125em;
  color: rgb(216, 222, 228);
}
.icon-red_hat_gray .path6:before {
  content: "\ea86";
  margin-left: -2.095703125em;
  color: rgb(216, 222, 228);
}
.icon-red_hat_gray .path7:before {
  content: "\ea87";
  margin-left: -2.095703125em;
  color: rgb(216, 222, 228);
}
.icon-red_hat_gray .path8:before {
  content: "\ea88";
  margin-left: -2.095703125em;
  color: rgb(201, 209, 218);
}
.icon-red_hat_gray .path9:before {
  content: "\ea89";
  margin-left: -2.095703125em;
  color: rgb(201, 209, 218);
}
.icon-aws-gris:before {
  content: "\e9ad";
  color: #d8dee4;
}
.icon-Microsoft-Azure-gris .path1:before {
  content: "\ea4f";
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path2:before {
  content: "\ea50";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path3:before {
  content: "\ea51";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path4:before {
  content: "\ea52";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path5:before {
  content: "\ea53";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path6:before {
  content: "\ea54";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path7:before {
  content: "\ea55";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path8:before {
  content: "\ea56";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path9:before {
  content: "\ea57";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path10:before {
  content: "\ea58";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path11:before {
  content: "\ea59";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path12:before {
  content: "\ea5a";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path13:before {
  content: "\ea5b";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path14:before {
  content: "\ea5c";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-Microsoft-Azure-gris .path15:before {
  content: "\ea5d";
  margin-left: -6.880859375em;
  color: rgb(186, 197, 207);
}
.icon-Microsoft-Azure-gris .path16:before {
  content: "\ea5e";
  margin-left: -6.880859375em;
  color: rgb(201, 209, 218);
}
.icon-Microsoft-Azure-gris .path17:before {
  content: "\ea5f";
  margin-left: -6.880859375em;
  color: rgb(216, 222, 228);
}
.icon-microsoft-azure-seeklogocom .path1:before {
  content: "\ea60";
  color: rgb(244, 244, 244);
}
.icon-microsoft-azure-seeklogocom .path2:before {
  content: "\ea61";
  margin-left: -6.880859375em;
  color: rgb(234, 234, 234);
}
.icon-microsoft-azure-seeklogocom .path3:before {
  content: "\ea62";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path4:before {
  content: "\ea63";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path5:before {
  content: "\ea64";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path6:before {
  content: "\ea65";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path7:before {
  content: "\ea66";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path8:before {
  content: "\ea67";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path9:before {
  content: "\ea68";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path10:before {
  content: "\ea69";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path11:before {
  content: "\ea6a";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path12:before {
  content: "\ea6b";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path13:before {
  content: "\ea6c";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path14:before {
  content: "\ea6d";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path15:before {
  content: "\ea6e";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path16:before {
  content: "\ea6f";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-microsoft-azure-seeklogocom .path17:before {
  content: "\ea70";
  margin-left: -6.880859375em;
  color: rgb(255, 255, 255);
}
.icon-aws:before {
  content: "\e963";
  color: #fff;
}
.icon-Ingram_Micro1:before {
  content: "\ea2f";
  color: #fff;
}
.icon-TechData1:before {
  content: "\ea30";
  color: #fff;
}
.icon-TechData2:before {
  content: "\ea31";
  color: #d8dee4;
}
.icon-Ingram_Micro2:before {
  content: "\ea32";
  color: #d8dee4;
}
.icon-azure-gray:before {
  content: "\e950";
  color: #d8dee4;
}
.icon-azure:before {
  content: "\e952";
  color: #fff;
}
.icon-down:before {
  content: "\e953";
  color: #132046;
}
.icon-ibm-gray:before {
  content: "\e955";
  color: #d8dee4;
}
.icon-ibm1:before {
  content: "\e957";
  color: #fff;
}
.icon-nyce-gray:before {
  content: "\e958";
  color: #d8dee4;
}
.icon-nyce1:before {
  content: "\e95a";
  color: #fff;
}
.icon-redis-gray .path1:before {
  content: "\e95d";
  color: rgb(201, 209, 218);
}
.icon-redis-gray .path2:before {
  content: "\e986";
  margin-left: -2.96875em;
  color: rgb(216, 222, 228);
}
.icon-redis-gray .path3:before {
  content: "\e987";
  margin-left: -2.96875em;
  color: rgb(201, 209, 218);
}
.icon-redis-gray .path4:before {
  content: "\e988";
  margin-left: -2.96875em;
  color: rgb(216, 222, 228);
}
.icon-redis-gray .path5:before {
  content: "\e989";
  margin-left: -2.96875em;
  color: rgb(201, 209, 218);
}
.icon-redis-gray .path6:before {
  content: "\e98a";
  margin-left: -2.96875em;
  color: rgb(216, 222, 228);
}
.icon-redis-gray .path7:before {
  content: "\e98b";
  margin-left: -2.96875em;
  color: rgb(255, 255, 255);
}
.icon-redis-gray .path8:before {
  content: "\e98c";
  margin-left: -2.96875em;
  color: rgb(186, 197, 207);
}
.icon-redis-gray .path9:before {
  content: "\e98d";
  margin-left: -2.96875em;
  color: rgb(231, 235, 238);
}
.icon-redis-gray .path10:before {
  content: "\e98e";
  margin-left: -2.96875em;
  color: rgb(216, 222, 228);
}
.icon-redis:before {
  content: "\e965";
  color: #fff;
}
.icon-salesforce-gray:before {
  content: "\e967";
  color: #d8dee4;
}
.icon-salesforce1:before {
  content: "\e969";
  color: #fff;
}
.icon-sap-gray:before {
  content: "\e96b";
  color: #d8dee4;
}
.icon-sap1:before {
  content: "\e96d";
  color: #fff;
}
.icon-terraform-gray:before {
  content: "\e96e";
  color: #d8dee4;
}
.icon-terraform1:before {
  content: "\e972";
  color: #fff;
}
.icon-twilio-gray:before {
  content: "\e973";
  color: #d8dee4;
}
.icon-twilio1:before {
  content: "\e974";
  color: #fff;
}
.icon-up:before {
  content: "\e975";
  color: #132046;
}
.icon-vtex-gray:before {
  content: "\e976";
  color: #d8dee4;
}
.icon-vtex1:before {
  content: "\e977";
  color: #fff;
}
.icon-capital_funding_lab:before {
  content: "\ea71";
  color: #fff;
}
.icon-rihee:before {
  content: "\e98f";
  color: #fff;
}
.icon-iem:before {
  content: "\e990";
  color: #fff;
}
.icon-GLD:before {
  content: "\ea33";
  color: #fff;
}
.icon-Rotoplas:before {
  content: "\ea34";
  color: #fff;
}
.icon-Coca-Cola:before {
  content: "\ea2e";
  color: #fff;
}
.icon-axa-keralty:before {
  content: "\e978";
  color: #fdffff;
}
.icon-axa:before {
  content: "\e979";
  color: #fdffff;
}
.icon-fimpe:before {
  content: "\e97a";
  color: #fff;
}
.icon-acuantia:before {
  content: "\e97b";
  color: #fff;
}
.icon-bebbia:before {
  content: "\e97c";
  color: #fff;
}
.icon-cardif:before {
  content: "\e97d";
  color: #fff;
}
.icon-cashclick .path1:before {
  content: "\e97e";
  color: rgb(255, 255, 255);
}
.icon-cashclick .path2:before {
  content: "\e991";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path3:before {
  content: "\e992";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path4:before {
  content: "\e993";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path5:before {
  content: "\e994";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path6:before {
  content: "\e995";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path7:before {
  content: "\e996";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path8:before {
  content: "\e997";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path9:before {
  content: "\e998";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path10:before {
  content: "\e999";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path11:before {
  content: "\e99a";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path12:before {
  content: "\e99b";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path13:before {
  content: "\e99c";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path14:before {
  content: "\e99d";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path15:before {
  content: "\e99e";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path16:before {
  content: "\ea23";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path17:before {
  content: "\ea24";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path18:before {
  content: "\ea25";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path19:before {
  content: "\ea26";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path20:before {
  content: "\ea27";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path21:before {
  content: "\ea28";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path22:before {
  content: "\ea29";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path23:before {
  content: "\ea2a";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path24:before {
  content: "\ea2b";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path25:before {
  content: "\ea2c";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path26:before {
  content: "\ea2d";
  margin-left: -1.25em;
  color: rgb(0, 0, 0);
}
.icon-cashclick .path27:before {
  content: "\ea35";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path28:before {
  content: "\ea36";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path29:before {
  content: "\ea37";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path30:before {
  content: "\ea38";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path31:before {
  content: "\ea39";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path32:before {
  content: "\ea3a";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path33:before {
  content: "\ea3b";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path34:before {
  content: "\ea3c";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-cashclick .path35:before {
  content: "\ea3d";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-disney:before {
  content: "\e9a0";
  color: #fff;
}
.icon-imss:before {
  content: "\e9a2";
  color: #fff;
}
.icon-invex:before {
  content: "\e9a3";
  color: #fff;
}
.icon-nestle:before {
  content: "\e9a4";
  color: #fff;
}
.icon-prosic:before {
  content: "\e9a5";
  color: #fff;
}
.icon-sabadell:before {
  content: "\e9a7";
  color: #fff;
}
.icon-santander:before {
  content: "\e9a8";
  color: #fff;
}
.icon-sodexo:before {
  content: "\e9a9";
  color: #fff;
}
.icon-soriana:before {
  content: "\e9aa";
  color: #fff;
}
.icon-universal:before {
  content: "\e9ab";
  color: #fff;
}
.icon-usher:before {
  content: "\e9ac";
  color: #fff;
}
.icon-adress:before {
  content: "\e9ae";
  color: #0075e2;
}
.icon-camera .path1:before {
  content: "\e9af";
  color: rgb(19, 32, 70);
}
.icon-camera .path2:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-camera .path3:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone:before {
  content: "\e9b2";
  color: #0075e2;
}
.icon-pin .path1:before {
  content: "\e9b3";
  color: rgb(255, 255, 255);
}
.icon-pin .path2:before {
  content: "\e9b4";
  margin-left: -0.6669921875em;
  color: rgb(0, 117, 226);
}
.icon-bullet1 .path1:before {
  content: "\e9b5";
  color: rgb(0, 117, 226);
}
.icon-bullet1 .path2:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-close-gray .path1:before {
  content: "\e9b7";
  color: rgb(216, 222, 228);
}
.icon-close-gray .path2:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-left-d:before {
  content: "\e9b9";
}
.icon-right-d:before {
  content: "\e9ba";
}
.icon-2bcore-o:before {
  content: "\e97f";
  color: #0075e2;
}
.icon-linkedin:before {
  content: "\e980";
  color: #132046;
}
.icon-facebook:before {
  content: "\e981";
  color: #132046;
}
.icon-2bcore-w-o:before {
  content: "\e982";
  color: #fff;
}
.icon-linkedin-w:before {
  content: "\e983";
  color: #fff;
}
.icon-facebook-w:before {
  content: "\e984";
  color: #fff;
}


.path {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

ul {
  list-style: none;
}


//apartadode imagenes de contacto

.img-contact {
  display: grid;
  margin-left: auto;

  @media(min-width: 991px) {
    
  }
}
.padding-contact {
  @media (max-width:575px){
    padding-left: 29px;
    padding-right: 29px;
  }
}
.contact-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
}

.img-box {
  position: relative;
  padding-left: 35px;
  @media (max-width: 450px) {
    transform: scale(.7);
    padding-left: 0;
  }
}
.move-left {
  @media (min-width: 991px) {
    transform: translateX(38px);
  }
 
}
.box-lg {
  height: 200px;
  width: 100%;
  @media (max-width: 450px) {
    transform: scale(.8);
    margin-left: -32px;
    margin-bottom: -30px;
  }
}

.box-sm {
  height: 150px;
  width: 100%;
  @media (max-width: 450px) {
    transform: scale(.8);
    margin-left: -32px;
    margin-bottom: -30px;
  }
}

.bubble {
  width: 180px;
  height: 127px;
  z-index: 2;
}

.arm {
  position: absolute;
  z-index: 3;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bubble-one {
  position: absolute;
  bottom: 18px;
  right: 15%;
}
.first-arm-one {
  height: 85px;
  bottom: 62px;
  right: 80px;
  animation: rotateInDownRightOne 2s cubic-bezier(.48, .82, .5, .19) infinite alternate;
}
.second-arm-one {
  height: 45px;
  bottom: 65px;
  right: 20px;
  animation: rotateInDownRightTwo 2s linear infinite alternate;
}
.first-arm-two {
  height: 80px;
  bottom: 55px;
  right: -12px;
  animation: rotateInDownLeftOne 2s cubic-bezier(.48, .82, .5, .19) infinite alternate;
}



.second-arm-two {
  bottom: 72px;
  right: -27px;
  height: 87px;
  animation: rotateInDownLeftTwo 2s linear infinite alternate;
}

.third-arm-one {
  height: 60px;
  bottom: 60px;
  right: 100px;
  animation: rotateInDownRight 2s linear infinite alternate;
}

.third-arm-two {
  height: 80px;
  bottom: 63px;
  right: 30px;
  animation: rotateInDownLeft 2s linear infinite alternate;
}

.fourth-arm-one {
  height: 90px;
  top: -16px;
  right: 97px;
  animation: rotateInDownLeftFour .6s linear infinite alternate;
}

.five-arm-one {
  height: 75px;
  top: 3px;
  left: 130px;
  animation: rotateInDownLeft 1.5s linear infinite alternate;
  @media (max-width: 450px) {
    left: 117px;
  }
}

.five-arm-two {
  height: 70px;
  top: 3px;
  left: 107px;
 
  @media (max-width: 450px) {
    left: 83px;
  }
}


.bubble-two {
  position: absolute;
  top: 0px;
  right: -45px;
}

.bubble-three {
  position: absolute;
  top: 5px;
  right: 25px;
}

.bubble-four {
  position: absolute;
  top: -6px;
  right: -12px;
}

.chair-img {
  position: relative;
  width: 350px;
  height: 182px;
}

@keyframes rotateInDownLeftOne {
    0% {
      transform-origin: left bottom;
      transform: rotate(-15deg);
    }
  
    100% {
      transform-origin: left bottom;
      transform: rotate(3deg);
    }
  }
  
  @keyframes rotateInDownLeftTwo {
    0% {
      transform-origin: left bottom;
      transform: rotate(-15deg);
    }
  
    100% {
      transform-origin: left bottom;
      transform: rotate(0);
    }
  }
  
  @keyframes rotateInDownLeftFour {
    0% {
      transform-origin: right bottom;
      transform: rotate(-15deg);
    }
  
    100% {
      transform-origin: right bottom;
      transform: rotate(0);
    }
  }
  
  @keyframes rotateInDownLeft {
    0% {
      transform-origin: left bottom;
      transform: rotate(-15deg);
    }
  
    100% {
      transform-origin: left bottom;
      transform: rotate(0);
    }
  }
  
  @keyframes rotateInDownRightOne {
    0% {
      transform-origin: right bottom;
      transform: rotate(0deg);
    }
  
    100% {
      transform-origin: right bottom;
      transform: rotate(-15deg);
    }
  }
  
  @keyframes rotateInDownRightTwo {
    0% {
      transform-origin: right bottom;
      transform: rotate(0deg);
    }
  
    100% {
      transform-origin: right bottom;
      transform: rotate(-10deg);
    }
  }
  
  @keyframes rotateInDownRightFive {
    0% {
      transform-origin: right bottom;
      transform: rotate(-5deg);
    }
  
    100% {
      transform-origin: right bottom;
      transform: rotate(0);
    }
  }
  
  
  @keyframes rotateInDownRight {
    0% {
      transform-origin: right bottom;
      transform: rotate(-15deg);
    }
  
    100% {
      transform-origin: right bottom;
      transform: rotate(0);
    }
  }