
/* Import Fonts */
@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Medium';
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Semibold';
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Light';
  src: url('/assets/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('/assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Fonts weight*/
.fw-regular{
  font-family: 'Lato';
}

.fw-medium{
  font-family: 'Lato-Medium';
  font-weight: 500;
}

.fw-light {
  font-family: 'Lato-Light';
  font-weight: 300;
}

.fw-bold {
  font-family: 'Lato-Bold';
}

/* COLOR TEXT */
.txt-grey{
  color: #666666;
}
.txt-darkgrey{
  color:#4D4D4D;
}
.txt-blue{
  color: #003f9b;
}
.txt-light-blue {
  color: #0075E2;
}
.txt-cyan{
  color: #559CFF;
}
.txt-cyan2 {
  color: #549CFE;
}
.txt-lightgray {
  color: #808080;
}
.txt-lightgray2{
  color: #999999;
}
.txt-blue3{
color:   #003C9E;
  line-height: 1;
}
.txt-red{
  color: #FD565A;
}
.txt-blue-light{
  color: #99C4FF;
}
.txt-lighter-grey{
  color:#B3B3B3;
}
.txt-principal-gray{
  color:#D8DEE4;
}
.txt-blue-link{
  color: #008FFF;
}


.text-underline{
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

.lh-2{
  line-height: 2;
}

.link {
  
}

.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// Loop para crear clases `fs-` que indica Font-Size de 7px a 40px
$class-fs: fs !default;

// Ejemplo: fs-12 = font-size: 12px;
@for $i from 5 through 151 {
  .#{$class-fs}-#{$i} {
    font-size: 0px + $i;
  }
}
// Loop para crear clases `w-` que indican el width en %
$class-w: w !default;

// Ejemplo: fs-12 = font-size: 12px;
@for $e from 1 through 99 {
  .#{$class-w}-#{$e} {
    width: 0% + $e;
  }
}

// Loop para crear clases `lh-` que indica line-height de 7px a 40px
$class-lh: lh !default;

// Ejemplo: lh-12 = line-height: 12px;
@for $i from 5 through 65 {
  .#{$class-lh}-#{$i} {
    line-height: 0px + $i;
  }
}

@media (max-width: 991px) {
  // Loop para crear clases `fs-` que indica Font-Size de 7px a 40px
  $class-fsm: fsm !default;

  // Ejemplo: fs-12 = font-size: 12px;
  @for $i from 7 through 65 {
    .#{$class-fsm}-#{$i} {
      font-size: 0px + $i;
    }
  }
}
