/* Global Styles Forms */

.form-label {}

.form-control {

  select {
    color: black;
  }

  &.ng-touched.ng-invalid,
  .ng-touched.ng-dirty,
  .ng-invalid {}
}

.form-control:focus {

  &.ng-touched.ng-invalid,
  .ng-touched.ng-dirty,
  .ng-invalid {}
}

.form-password {
  position: relative;

  img {
    position: absolute;
    right: 10px;
    width: 15.55px;
    top: 25%;
  }
}

.input-error {
  border: 1px solid #C91432 !important;
}

.text-red {
  position: absolute;
  font-size: 9px;
  color: #C91432;
  left: 0;
}

.alert-invalid {
  position: relative;
}

.form-group {
  margin-bottom: 5px;
}

// .container input[type="checkbox"] {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }

.checkmark-square {
  position: absolute;
  left: 0;
  height: 19px;
  width: 19px;
  margin-left: 9px;
  border-radius: 7px;
  background-color: #ffffff;
  border: 0.5px solid #B3B3B3;
}

.container input:checked~.checkmark-square {
  background-color: #0075E2;
  border: 0.5px solid #0075E2;
}

.checkmark-square:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked~.checkmark-square:after {
  display: block;
}

.container .checkmark-square:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-control:focus {
  border-color: #cccccc;
}

.text-danger {
  position: relative;
  top: -34px;
  left: 14px;
  font-size: 18px;
  color: #DD0202;
}