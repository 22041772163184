/* Color Palete */

// BLUE
$blue-no5: #132046;
$blue-dark:#0A1328;
$blue-background: #050812;
$blue-light: #4F8AE0;
$_2bcore-blue: #0075E2;

// WHITE
$white1: #FFFFFF;

// GREY
$grey-323: #323232;

// BLACK
$black1: #000000;

// RED
$red1: #DD0202;
