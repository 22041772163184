/* Global Styles Buttons */

$gray-primary: rgba(227,227,227,0.2);
$gray-secondary: rgba(227,227,227,0.35);
$box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
$border: 1.5px solid #FFFFFF;

.btn-primary{
  box-sizing: border-box;
  height: 48px;
  min-width: 156px;
  border: $border;
  border-radius: 10px;
  background-color: $gray-primary;
  box-shadow: $box-shadow;
  &:hover{
    background-color: $gray-secondary;
    box-shadow: $box-shadow;
    border: $border
  }

  .btn-check:focus + &, &:focus {
    background-color: $gray-secondary;
    box-shadow: $box-shadow;
    border: $border;
  }

  .btn-check:checked + &,.btn-check:active + &, &:active, &.active {
    background-color: $gray-secondary;
    box-shadow: $box-shadow;
    border: $border;
    &:focus {
      background-color: $gray-secondary;
      box-shadow: $box-shadow;
      border: $border;
    }
  }
  
  &:disabled{ 
    box-sizing: border-box;
    height: 48px;
    min-width: 156px;
    border: $border;
    border-radius: 10px;
    background-color: $gray-primary;
    box-shadow: $box-shadow;   
    opacity: 40%;
  }
}
.btn-secondary{
  box-sizing: border-box;
  height: 48px;
  min-width: 156px;
  border-radius: 10px;
  background-color: $gray-secondary;
  box-shadow: $box-shadow;
  border: $border;
  &:hover{
    border: $border;
    background-color: $gray-primary;
    box-shadow: $box-shadow;
  }
  .btn-check:focus + &, &:focus {
    border: $border;
    background-color: $gray-primary;
    box-shadow: $box-shadow;
  }

  .btn-check:checked + &,.btn-check:active + &, &:active, &.active {
    border: $border;
    background-color: $gray-primary;
    box-shadow: $box-shadow;
    &:focus {
      border: $border;
      background-color: $gray-primary;
      box-shadow: $box-shadow;
    }
  }

  &:disabled{ 
    box-sizing: border-box;
    height: 48px;
    min-width: 156px;
    border-radius: 10px;
    background-color: $gray-secondary;
    box-shadow: $box-shadow;
    border: $border;
    opacity: 40%;      
  }
}
.btn-blue {
  height: 50px;
  width: 184px;
  border-radius: 10px;
  background-color: #0075E2;
  border: none;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  &:hover{
    border: solid 1px #0075E2; 
    color: #0075E2;
  }
}
.cr-pointer{
  cursor: pointer;
}


